

































import schoolIntroduce from './components/school_introduce/school_introduce.vue';
import schoolInfo from './components/school_info/school_info.vue';
import schoolMedia from './components/school_media/school_media.vue';
import SubmitBtnGroup from '@/components/SubmitBtnGroup.vue';

import { Vue, Component, Provide, Watch } from 'vue-property-decorator';
import { getSchoolDetailApi } from '@/api/school_api';
import { debounce } from '@/utils/delayed';
import { hasVal } from '@/utils/index';
import { UserStoreModule } from '@/store/UserModule';
@Component({
  name: 'schoolDetail',
  components: { schoolIntroduce, schoolInfo, schoolMedia, SubmitBtnGroup
}})
export default class SchoolDetail extends Vue{
  @Provide() schoolType = 'annual'
  isEdit = false;
  // 防抖
  debounceInit = debounce(this.getSchoolDetail, 500, true);
  schoolDetailData: any = {};
  fkInstitutionId: number | string = '';
  // 附件数据
  mediaDetailList: any = {};

  get isAnnualMember(){
    return UserStoreModule.isAnnualMember;
  }
  @Watch('isAnnualMember', {immediate: true})
  isAnnual(val:boolean){
    if(val){
      this.isEdit = true
    }
  }
  mounted() {
    this.getSchoolDetail();
  }
  // 会重新加载
  // private async beforeRouteUpdate(to: CusRoute, from: CusRoute, next: Function){
  //   await this.getSchoolDetail();
  //   next();
  // }
  // 获取详情
  getSchoolDetail() {
    const guid = this.$route.params.id;
    getSchoolDetailApi(guid).then((res: any) =>{
      const reqData = res.data.data;
    if(reqData) {
      this.schoolDetailData = reqData;
    }else {
      this.schoolDetailData = {}
    }
    
    if(reqData.institutionDto) {
      this.fkInstitutionId = reqData.institutionDto.id;
      this.mediaDetailList = [];
      if(hasVal(reqData.pdfFile)) {
        this.mediaDetailList.push(...reqData.pdfFile)
      }
      if(hasVal(reqData.picFile) && reqData.picFile.length!==0) {
        this.mediaDetailList.push(...reqData.picFile)
      }
      if(hasVal(reqData.movFiles) && reqData.movFiles.length!==0) {
        this.mediaDetailList.push(...reqData.movFiles)
      }
      if(hasVal(reqData.background)&& reqData.background.length!==0) {
        this.mediaDetailList.push(...reqData.background)
      }
      if(hasVal(reqData.cityBackground)&& reqData.cityBackground.length!==0) {
        this.mediaDetailList.push(...reqData.cityBackground)
      }
    }
  }).catch((err: any) => {
    this.$router.replace({ name: 'adminSchoolList'})
  })
  
  }
}
